angular.module('cpformplastApp')
  .controller('CategoryCreationModal', function ($scope, $modalInstance, Notification, DataManager) {

    $scope.types = [
      'HIPS',
      'PET',
      'PVC',
      'AUTRE'
    ];
    $scope.category = {};
    $scope.submitted = false;

    /**
     * Validate the category submission
     * @param {Object} form - the category form
     * @param {Boolean} create - if 'true' go to a new category creation page
     * @param {Object} message - to customize the output message
     * @return {Boolean}
     */
    $scope.submitCategory = function(form) {

      //Initialisation
      $scope.submitted = true;
      if (!form.$valid){ return false; }

      //Success
      DataManager.createCategory($scope.category).then(data => {
        Notification.success(`La categorie fut créée`);
        $scope.$dismiss();
      })
       //Failure
      .catch(err => {
        console.log(err);

        if (err.data.err.message) {
          Notification.error(err.data.err.message);
        } else {
          Notification.error('Un problème est survenu');
        }
      });

    };

  });
